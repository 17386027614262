import * as React from 'react';
import {useEffect} from 'react';
import '../styles/App.less';
import {setUTMCookie} from '../utils/CookieUtil';
import {Helmet} from 'react-helmet';
import {navigate, PageProps} from 'gatsby';
import {Result, Spin} from 'antd';
import {useAuth} from '../components/Auth/AuthContext';
import {useQueryParam} from 'gatsby-query-params';
import {isEmpty} from 'lodash';
import {getMemberships} from '../services/API';
import {Membership} from 'shared/src/types';
import {CriblCloudRole} from 'shared/src/user/types';
import {Space} from 'antd';

const Callback = (props: PageProps) => {
  setUTMCookie(props);
  const error = useQueryParam('error');
  const errorDescription = useQueryParam('error_description');
  const {getAccessTokenSilently, getIdTokenClaims, authToken, handleRedirectCallback, isLoading, user} = useAuth();
  const roleError = 'Your role is not configured correctly';

  const fetchMemberships = async (): Promise<Membership[]> => {
    const idToken = await getIdTokenClaims();
    let token: string;
    try {
      token = authToken || (await getAccessTokenSilently());
    } catch (e) {
      // Do nothing
    }
    const response = await getMemberships(token, idToken?.__raw);
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return [];
  };

  useEffect(() => {
    if (isLoading || error) return;
    // This is a temporary fix for the issue where the user is not redirected due to 3p cookies being blocked
    const timer = setTimeout(() => window?.location?.reload(), 5000);
    (async () => {
      if (!isEmpty(user) && ['auth0', 'google-oauth2'].includes(user.sub.split('|')?.[0]) && !user?.email_verified) {
        await navigate('/verify');
        return;
      }
      if (isEmpty(user)) {
        try {
          const redirectLoginResults = await handleRedirectCallback();
          if (redirectLoginResults) {
            const {appState} = redirectLoginResults;
            if (appState?.nonceReturnTo) {
              const returnTo = localStorage.getItem(appState.nonceReturnTo);
              if (returnTo) {
                localStorage.removeItem(appState.nonceReturnTo);
                return await navigate(returnTo);
              }
            }
          }
        } catch (e) {
          console.error(e);
          return;
        }
      }

      if (!isEmpty(user)) {
        const organizations = user?.['https://cribl.cloud/organizations'];
        if (
          organizations?.length === 1 &&
          !user?.['https://cribl.cloud/cloudRole']?.find(
            (role) => role === CriblCloudRole.Admin || role === CriblCloudRole.SE || role === CriblCloudRole.Support
          )
        ) {
          const memberships = await fetchMemberships();
          await window.location.replace(memberships[0].url);
          return;
        } else {
          await navigate('/organizations');
        }
      }
    })();
    return clearTimeout(timer);
  }, [isLoading, user]);

  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Cribl Cloud is the leading data observability platform in the world. Try LogStream in the cloud today!'
        />
        <title>Cribl.Cloud </title>
        <link rel='canonical' href='https://cribl.cloud' />
      </Helmet>
      <main className={'main-holder-auth0'}>
        {error && (
          <Space direction='horizontal' style={{width: '100%', justifyContent: 'center'}}>
            <Result
              className={'main-container'}
              status='error'
              title={error}
              subTitle={errorDescription}
              extra={
                errorDescription === roleError ? (
                  <>
                    Please visit the{' '}
                    <a href='https://docs.cribl.io/stream/cloud-sso#configuring-groups'>cloud sso group mapping docs</a>{' '}
                    for more information.
                  </>
                ) : undefined
              }
            ></Result>
          </Space>
        )}
        {!error && <Spin size='large' className={'spinner-auth0'} />}
      </main>
    </>
  );
};

export default Callback;
